import { Controller } from '@hotwired/stimulus';
import Tagify from '@yaireo/tagify';
import { httpRequest } from './controller_helpers';

export default class Tags extends Controller {
  static targets = ['tagger'];
  static values = {
    anonymousTagger: Boolean,
    availableTags: String,
    entityType: String,
    entityId: String,
    filterSelectTagUrl: String,
    isUserInputEnabled: Boolean,
    listDelimiter: String,
    redirectTagsScrollTo: String,
    redirectTagsUrl: String,
    url: String,
  };

  initialize() {
    this.tagger = null;
    this.tagList = this.availableTags();
    this.taggableContainer = document.getElementById('tag-events');
  }

  connect() {
    this.mountTagger();

    // Clear Turbo cache to prevent duplicate element render on 'Back' button presses.
    document.addEventListener(
      'turbo:before-cache',
      () => {
        this.tagger.destroy();
      },
      { once: true },
    );
  }

  mountTagger() {
    let inputEnabled = Boolean(this.isUserInputEnabledValue);

    this.tagger = new Tagify(this.taggerTarget, {
      whitelist: this.tagList,
      addTagOnBlur: false,
      backspace: false,
      editTags: false,
      userInput: inputEnabled,
      delimiters: this.listDelimiterValue,
      dropdown: {
        caseSensitive: false,
        classname: 'tags-selectable',
        enabled: inputEnabled ? 0 : false, // 0 is enabled, as in, show/reveal suggestions after 0 typed characters
        fuzzySearch: true,
        maxItems: inputEnabled ? 20 : 0, // 0 actually disables the dropdown, when userInput is set to false
        position: 'text',
      },
    });

    if (!this.isAnonymousTagger()) {
      this.registerEvents();
    }
  }

  isAnonymousTagger = () => {
    return Boolean(this.anonymousTaggerValue);
  };

  registerEvents = () => {
    // While Tagify wraps an input element, events get fired from a span element
    // injected within the input. Therefore, initialize tagify instance with
    // event handlers as prescribed, rather than invoking via stimulus actions.
    this.tagger.on('add', this.addTagging);
    this.tagger.on('remove', this.removeTagging);
    this.tagger.on('click', this.clickTag);

    if (this.taggableContainer) {
      this.taggableContainer.addEventListener('easyredir:redirectTagListUpdate', (event) => this.updateTagList(event));
    }

    this.clickablePlaceholder();
  };

  // Tagify's notion of an empty placeholder is to put a `&ZeroWidthSpace` character as the span's content.
  stripZeroWidthSpaces = (str) => {
    return str.replace(/[\u200B-\u200D\uFEFF]/g, '');
  };

  updateTagList = (e) => {
    this.tagger.whitelist = [...new Set([...this.tagList, ...e.detail])].sort(); // array union
  };

  clickTag = (event) => {
    Turbo.visit(this.filterBySelectedTagDestination(event.detail.data.value));
  };

  clickablePlaceholder = (event) => {
    if (this.isUserInputEnabledValue || !this.hasRedirectTagsUrlValue) return;

    let placeholderSpan = this.element.querySelector('.tagify__input');
    if (this.stripZeroWidthSpaces(placeholderSpan.dataset.placeholder) !== '') {
      placeholderSpan.addEventListener('click', this.toRedirectTags);
    }
  };

  toRedirectTags = (event) => {
    Turbo.visit(this.redirectTagsUrlValue);
    this.dispatch('toRedirectTags', { detail: { scrollTo: this.redirectTagsScrollToValue } });
  };

  addTagging = (event) => {
    httpRequest('POST', this.urlValue, this.payload(event))
      // Make newly-added tag available to other tagger widget tag lists on this page.
      .then((response) => this.sendTagListUpdateEvent(this.toTagArray(response.tags)));
  };

  removeTagging = (event) => {
    // In absence of an explicit RESTful ID, tag value and associated taggable are sent in API payload body.
    httpRequest('DELETE', this.urlValue, this.payload(event));
  };

  sendTagListUpdateEvent = (list) => {
    this.taggableContainer.dispatchEvent(new CustomEvent('easyredir:redirectTagListUpdate', { detail: list }));
  };

  availableTags = () => {
    return this.toTagArray(this.availableTagsValue);
  };

  toTagArray = (tagsString) => {
    return tagsString.split(this.listDelimiterValue).filter(Boolean); // filter out null, undefined, "", etc. from array list
  };

  payload = (event) => {
    return {
      tag: event.detail.data.value,
      entityType: this.entityTypeValue,
      entityId: this.entityIdValue,
    };
  };

  filterBySelectedTagDestination = (tagName) => {
    let url = new URL(this.hasFilterSelectTagUrlValue ? this.filterSelectTagUrlValue : window.location.href);
    url.searchParams.set('q', this.listDelimiterValue + tagName + this.listDelimiterValue);

    return url;
  };
}
