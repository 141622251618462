import { Controller } from '@hotwired/stimulus';
import animateScrollTo from 'animated-scroll-to';

export default class extends Controller {
  static targets = ['scrollDestination'];
  static values = {
    wait: Number,
  };

  executeScroll(event) {
    setTimeout(() => {
      let target = event?.detail?.scrollTo;
      let destination = target ? document.querySelector(target) : this.scrollDestinationTarget;

      if (destination) animateScrollTo(destination);
    }, this.scrollWait);
  }

  get scrollWait() {
    return this.waitValue;
  }
}
