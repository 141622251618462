import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.windowReference = null;
  }

  disconnect() {
    if (this.windowReference && this.windowReference.closed === false) {
      this.windowReference.close();
    }
  }

  openPopUp(event) {
    event.preventDefault();
    let href = event.currentTarget.href;
    let popup_top = $(window).height() / 2 - 225;
    let popup_left = $(window).width() / 2;
    this.windowReference = window.open(
      href,
      'er-popup-window',
      'width=550, height=450, top=' + popup_top + ', left=' + popup_left + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0',
    );
  }
}
