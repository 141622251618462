import { Controller } from '@hotwired/stimulus';
import { getJSON } from './controller_helpers';

export default class extends Controller {
  static targets = ['select'];
  static values = {
    collectionUrl: String,
  };

  connect() {
    this.select2mount();

    document.addEventListener(
      'turbo:before-cache',
      () => {
        this.select2unmount();
      },
      { once: true },
    );
  }

  select2mount() {
    const options = this.buildSelect2Options();

    // Wire up regular change events because apparently v4 of select2 does not fire them, even though the docs say they
    // do, and it just cost me about 3 hours figuring that out.
    this.$select
      .select2(options)
      .on('select2:select', () => {
        let event = new CustomEvent('change', { bubbles: true });
        this.selectTarget.dispatchEvent(event);
      })
      .on('select2:unselect', () => {
        let event = new CustomEvent('change', { bubbles: true });
        this.selectTarget.dispatchEvent(event);
      });

    // If we have a collection URL present (i.e. we are using Ajax to lookup data), check if there are initial values
    // selected and query the API to populate the select input with the initial values.
    if (this.collectionUrl) {
      const collectionUrl = this.collectionUrl;
      const selectedIds = Array.from(this.selectTarget.selectedOptions)
        .filter((option) => {
          return option.value !== '';
        })
        .map((option) => {
          return option.value;
        });

      if (selectedIds.length > 0) {
        const queryConnector = collectionUrl.indexOf('?') === -1 ? '?' : '&';
        const queryUrl = `${collectionUrl}${queryConnector}id=${selectedIds}`;

        // Display "Loading..." in the select while we query the API and prepare the results
        const loadingOption = new Option('Loading...', 'loading', true, true);
        this.$select.trigger('change');

        getJSON(queryUrl, (jsonResponse) => {
          const results = jsonResponse.results;

          // Clear existing options from select input
          while (this.selectTarget.options.length > 0) {
            this.selectTarget.remove(0);
          }
          // Iterate through the results we received and create new select Option elements
          results.forEach((result) => {
            this.selectTarget.add(new Option(result.text, result.id, true), undefined);
          });
          // Trigger the select2:select event and pass our data to allow other handlers to access these results
          // https://select2.org/programmatic-control/add-select-clear-items
          this.$select.trigger({
            type: 'select2:select',
            params: {
              data: results,
            },
          });
        });
      }
    }

    // Enable the select2 to be reinitialized by using our own event
    this.selectTarget.addEventListener(
      'select2:reinitialize',
      () => {
        this.select2unmount();
        this.select2mount();
      },
      { once: true },
    );
  }

  select2unmount() {
    if (this.$select.data('select2')) {
      this.saveState();
      this.$select.select2('destroy').off('select2:select').off('select2:unselect');
    }
  }

  saveState() {
    let selected = this.$select.val();

    // Make sure the HTML itself has those elements selected, since the HTML is what is saved in the Turbo snapshot
    (selected instanceof Array ? selected : [selected]).forEach((val) => {
      this.$select.find(`option[value="${val}"]`).attr('selected', 'selected');
    });
  }

  buildSelect2Options() {
    const options = {
      dropdownParent: this.$select.closest('.modal').length ? this.$select.closest('.modal') : $(document.body),
    };

    const minimumResultsForSearch = this.$select.data('minimum-results-for-search');
    if (minimumResultsForSearch) {
      options['minimumResultsForSearch'] = minimumResultsForSearch;
    } else {
      options['minimumResultsForSearch'] = 4;
    }

    const placeholder = this.$select.data('placeholder');
    if (placeholder) {
      options['placeholder'] = placeholder;
    }

    // If we've been supplied with a collectionUrl we should wire up all necessary functionality
    if (this.collectionUrl) {
      // Set up the Ajax options
      options['ajax'] = {
        type: 'GET',
        dataType: 'json',
        delay: 250,
        cache: true,
        contentType: 'application/json; charset=utf-8',
        accepts: {
          json: 'application/json',
        },
        url: this.collectionUrl,
        data: (params) => {
          return { q: params.term, page: params.page || 1 };
        },
        processResults: (data) => {
          return { results: data.results, pagination: { more: data.pagination.more } };
        },
      };
    }

    return options;
  }

  get $select() {
    return $(this.selectTarget);
  }

  get collectionUrl() {
    return this.hasCollectionUrlValue ? this.collectionUrlValue : null;
  }
}
