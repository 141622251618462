export const getChargebeeInstance = () => {
  return Chargebee.init({
    site: 'easyredir',
    publishableKey: 'live_TGk6gxeb5wc6RN3DcdbL4dK4XeNniGiEu',
  });
}

export const unmountChargebeeInstance = () => {
  return Chargebee.tearDown();
}
