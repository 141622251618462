import * as Castle from "@castleio/castle-js"
var castle = Castle.configure({pk: 'pk_hqxo4rHYDCkxp1RAm8AQRpvZ6bhhmjge'});

document.addEventListener("turbo:load", function() {
  let form = document.getElementById('new_user') || document.getElementById('new_registration_form');

  if (form) {
    form.addEventListener("submit", function(evt) {
      castle.injectTokenOnSubmit(evt);
    });
  }
});
