import { Autocomplete } from 'stimulus-autocomplete';

// TODO: Remove once audit_controller moves to use SearchFilterSupport concern.
const LEGACY_SELECTION_DELIMITER = 'tag=';

export default class SearchAutocomplete extends Autocomplete {
  static get targets() {
    return (Autocomplete.targets || []).concat(['previousSearch']);
  }

  static values = {
    selectionDelimiter: String,
  };

  initialize() {
    this.selectionDelimiter = this.selectionDelimiterValue || LEGACY_SELECTION_DELIMITER;
  }

  toggleResults() {
    if (!this.resultsShown || !this.options || this.options.length == 0) {
      this.element.classList.remove('autocomplete-active');
    }
  }

  loadResults() {
    if (this.options.length > 0) {
      this.element.classList.add('autocomplete-active');
    } else {
      this.element.classList.remove('autocomplete-active');
    }
  }

  optionSelected(evt) {
    // when an option is selected, apply the new
    // tag to previous search else the clicked value
    evt.stopImmediatePropagation();
    this.resultsTarget.hidden = true;

    const selection = evt.detail.value.trim();

    const val = selection.startsWith(this.selectionDelimiter) ? [this.previousSearchTarget.value, selection].join(' ') : selection;
    this.hiddenTarget.value = [val].concat(this.appliedTags()).join(' ');
    this.inputTarget.form.submit();
  }

  submitSearch(_evt) {
    this.resultsTarget.hidden = true;
    this.hiddenTarget.value = [this.inputTarget.value].concat(this.appliedTags()).join(' ');
  }

  appliedTags() {
    let tags = [];
    document.getElementsByName('tags[]').forEach((t) => {
      tags.push(this.toTagToken(t.value));
    });
    return tags;
  }

  // Example: 'abc def' => ';;abc def;;'
  toTagToken(str) {
    return this.selectionDelimiter + str + this.selectionDelimiter;
  }
}
