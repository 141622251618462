import * as Castle from "@castleio/castle-js"
var castle = Castle.configure({pk: "pk_hqxo4rHYDCkxp1RAm8AQRpvZ6bhhmjge"});

document.addEventListener("submit", (evt) => {
  if (evt.target.action.endsWith("sign-out")) {
    castle.injectTokenOnSubmit(evt);
  }
});

document.addEventListener("turbo:load", () => {
  // Hook up sign-out button so that we reset analytics cookies
  const signOutButtons = $("a.er-nav-sign-out");
  signOutButtons.on("click", () => {
    console.log("Processing sign out");
    Turbo.cache.clear();
    if (window.shouldEmitAnalytics) {
      console.log("Resetting cookies on sign out");
      analytics.reset();
      Intercom("shutdown");
    }
  });
});
