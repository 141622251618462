import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  initialize() {
    this.element[this.controllerIdentifier] = this;
  }

  get controllerIdentifier() {
    return this.identifier
      .split('--')
      .slice(-1)[0]
      .split(/[-_]/)
      .map((w) => w.replace(/./, (m) => m.toUpperCase()))
      .join('')
      .replace(/^\w/, (c) => c.toLowerCase());
  }

  trackEvent(event, properties, options) {
    analytics.track(event, properties, options);
  }
}
