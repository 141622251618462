// Issues a GET request to the supplied URL, and if the response is OK then executes the supplied successFunction
// and passes it the response JSON.
const getJSON = (url, successFunction) => {
  fetch(url, {
    method: 'GET',
    mode: 'same-origin',
    redirect: 'follow',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response;
    })
    .then((response) => response.json())
    .then((json) => {
      successFunction(json);
    })
    .catch((error) => {
      console.log(error);
    });
};

// TODO: Consolidate existing implementation of postJSON into httpRequest
const postJSON = (url, data) => {
  return fetch(url, {
    method: 'POST',
    mode: 'same-origin',
    body: JSON.stringify(data),
    redirect: 'follow',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': $('[name="csrf-token"]')[0].content,
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response;
    })
    .then((response) => response.json());
};

const httpRequest = (httpMethod, url, data) => {
  if (httpMethod != 'POST' && httpMethod != 'DELETE') {
    throw Error('Invalid HTTP method for request');
  }

  return fetch(url, {
    method: httpMethod,
    mode: 'same-origin',
    body: JSON.stringify(data),
    redirect: 'follow',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': $('[name="csrf-token"]')[0].content,
    },
  })
    .then(checkResponse)
    .then(parseResponse);
};

const checkResponse = (response) => {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
};

const parseResponse = (response) => {
  return response.json();
};

export { getJSON, postJSON, httpRequest };
