import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['flashes', 'otpAttempt', 'submitButton'];

  static values = {
    authToken: String,
    finishPath: String,
    otpEnabled: String,
    submitPath: String,
  };

  onsubmit(event) {
    this.submitButtonTarget.disabled = true;

    const form = new URLSearchParams();
    form.append('otp_required_for_login', this.otpEnabledValue);
    form.append('otp_attempt', this.otpAttemptTarget.value);

    fetch(this.submitPathValue, {
      method: 'post',
      body: form,
      headers: { 'X-CSRF-Token': this.authTokenValue },
    })
      .then((response) => {
        return response.text();
      })
      .then((body) => {
        if (body == 'success') {
          window.location.replace(this.finishPathValue);
        } else {
          this.flashesTarget.innerHTML = body;
          this.submitButtonTarget.disabled = false;
        }
      });

    event.preventDefault();
  }
}
