import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['selectAllCheckbox', 'rowCheckbox', 'bulkActionsContextContainer', 'bulkActionsMessage', 'selectedRows'];

  connect() {
    // Ensure Turbo caches do not have any rows selected
    document.addEventListener(
      'turbo:before-cache',
      () => {
        if (this.hasSelectAllCheckboxTarget) {
          this.setAllRowCheckboxStates(false);
          this.setSelectedRows();
          this.setContextContainerState(false);
          this.syncSelectAllCheckbox();
        }
      },
      { once: true },
    );
  }

  toggleSelectAll() {
    if (this.selectAllCheckboxTarget.checked) {
      this.setAllRowCheckboxStates(true);
      this.setContextContainerState(true);
      this.updateBulkActionsMessage();
    } else {
      this.setAllRowCheckboxStates(false);
      this.setContextContainerState(false);
    }
    this.setSelectedRows();
  }

  toggleRow() {
    this.syncSelectAllCheckbox();
    this.setSelectedRows();
    this.setContextContainerState(this.isAnyRowSelected());
    this.updateBulkActionsMessage();
  }

  setContextContainerState(state) {
    if (state) {
      this.bulkActionsContextContainerTarget.classList.add('selected');
    } else {
      this.bulkActionsContextContainerTarget.classList.remove('selected');
    }
  }

  setAllRowCheckboxStates(state) {
    for (let checkbox of this.rowCheckboxTargets) {
      checkbox.checked = state;
      checkbox.dispatchEvent(new CustomEvent('change'));
    }
  }

  syncSelectAllCheckbox() {
    if (this.areAllRowsSelected()) {
      this.selectAllCheckboxTarget.checked = true;
      this.selectAllCheckboxTarget.indeterminate = false;
    } else if (this.isAnyRowSelected()) {
      this.selectAllCheckboxTarget.checked = false;
      this.selectAllCheckboxTarget.indeterminate = true;
    } else {
      this.selectAllCheckboxTarget.indeterminate = false;
      this.selectAllCheckboxTarget.checked = false;
    }
  }

  setSelectedRows() {
    if (this.hasSelectedRowsTarget) {
      this.selectedRowsTarget.value = this.selectedRows().map((row) => row.value);
    }
  }

  selectedRows() {
    return this.rowCheckboxTargets.filter((checkbox) => checkbox.checked);
  }

  areAllRowsSelected() {
    return this.rowCheckboxTargets.every((checkbox) => checkbox.checked);
  }

  isAnyRowSelected() {
    return this.rowCheckboxTargets.some((checkbox) => checkbox.checked);
  }

  isNoRowSelected() {
    return !this.rowCheckboxTargets.some((checkbox) => checkbox.checked);
  }

  updateBulkActionsMessage() {
    if (this.hasBulkActionsMessageTarget) {
      let itemCount = this.selectedRows().length;
      this.bulkActionsMessageTarget.textContent = `${itemCount} items selected`;
    }
  }
}
