import { Controller } from '@hotwired/stimulus';

export default class BootstrapModal extends Controller {
  static targets = ['modal'];

  connect() {
    // Stimulus only understands an element's native events, and can't read jQuery events.
    // Replace jQuery event with fired native event to make accessible via `data-action` Stimulus invocation.
    // See: https://discuss.hotwired.dev/t/listening-to-jquery-events-on-stimulus-actions-like-ajax-success/573
    $(this.modalTarget).on('show.bs.modal', () => {
      let event = new CustomEvent('easyredir:modal.open', { bubbles: true });
      this.modalTarget.dispatchEvent(event);
    });

    $(this.modalTarget).on('hide.bs.modal', () => {
      let event = new CustomEvent('easyredir:modal.close', { bubbles: true });
      this.modalTarget.dispatchEvent(event);
    });
  }
}
