/* eslint no-console:0 */

// Import various polyfills
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'whatwg-fetch';
import '../utils/custom_events_polyfill';
import 'datadog/index.js.erb';
import 'bugsnag/index.js.erb';

// Require images
const images = require.context('../images/', true, /\.(gif|png|jpg|jpeg|svg|ico)$/i);

// Start the real app stuff
import Rails from '@rails/ujs';
import '@hotwired/turbo-rails';
import 'channels';

Rails.start();
Turbo.setFormMode('optin');

import 'timeago';
import 'theme';
import 'controllers';
