import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['checkbox', 'container'];

  toggleRow() {
    if (this.checkboxTarget.checked) {
      this.containerTarget.classList.add('selected');
    } else {
      this.containerTarget.classList.remove('selected');
    }
  }
}
