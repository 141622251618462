import { Controller } from '@hotwired/stimulus';

export default class ListFilter extends Controller {
  static targets = ['source', 'filterable', 'noMatch', 'noMatchTerm'];
  static classes = ['noshow'];

  initialize() {
    this.listState = {
      itemsFilterable: null,
      itemsFiltered: null,
    };
  }

  reset(event) {
    this.sourceTarget.value = '';
  }

  filter(event) {
    let filterTerm = this.sourceTarget.value.toLowerCase();
    let filterableTargetsCount = this.filterableTargets.length;
    let filteredCount = 0;

    this.filterableTargets.forEach((el, i) => {
      let filterableKey = el.getAttribute('data-filter-key').toLowerCase();

      el.classList.toggle(this.noshowClass, !filterableKey.includes(filterTerm));
      if (!filterableKey.includes(filterTerm)) {
        filteredCount++;
      }
    });

    this.listState.itemsFilterable = filterableTargetsCount;
    this.listState.itemsFiltered = filteredCount;
    this.sourceTarget.dispatchEvent(new CustomEvent('easyredir:list.filter.state', { detail: this.listState, bubbles: true }));

    // Reveal section to create new item when no matches are found (i.e. when all list items are filtered out)
    if (this.hasNoMatchTarget && this.hasNoMatchTermTarget) {
      if ((filterableTargetsCount > 0 || this.sourceTarget.value.length > 0) && filterableTargetsCount == filteredCount) {
        this.noMatchTermTarget.innerHTML = this.sourceTarget.value;
        this.noMatchTarget.classList.remove(this.noshowClass);
      } else {
        this.noMatchTermTarget.innerHTML = null;
        this.noMatchTarget.classList.add(this.noshowClass);
      }
    }
  }
}
