document.addEventListener('turbo:load', () => {
  // Activate Intercom tab open buttons
  const intercom_openers = $('a.intercom_open');

  if (intercom_openers.length) {
    intercom_openers.on('click', () => {
      if (!!window.Intercom && document.body.dataset.userChat === 'true') {
        window.Intercom('showNewMessage');
        return false;
      }
    });
  }
});
