import unescape from 'lodash/unescape';
import RedirectEntryController from './redirect_entry_controller';

export default class extends RedirectEntryController {
  static targets = ['singleInput', 'matchType', 'hiddenSourceUrl'];

  static values = {
    sourceUrl: String,
    partialMatchesEnabled: Boolean,
  };

  connect() {
    if (this.singleInputTarget.innerHTML == '') {
      this.singleInputTarget.innerHTML = this.sourceUrlValue;
    }
    this.sourceUpdated();
  }

  submitForm(e) {
    this.sourceUpdated();
    $(e.currentTarget).closest('form').submit();
  }

  sourceUpdated(_e) {
    const e = { currentTarget: { value: this.parseURL() } };
    super.sourceUpdated(e);
    this.hiddenSourceUrlTarget.value = this.parseURL();
    if (this.partialMatchesEnabledValue) {
      const updatedSourceURL = this.formatSourceURL();

      if (updatedSourceURL !== unescape(this.singleInputTarget.innerHTML)) {
        this.singleInputTarget.innerHTML = updatedSourceURL;
        this.moveCaretToEnd(this.singleInputTarget);
      }
      this.updateMatchType();
    }
  }

  parseURL() {
    let url = '';
    for (let node of this.singleInputTarget.childNodes) {
      url = url.concat(this.parseNode(node));
    }
    return url;
  }

  parseNode(node) {
    switch (node.nodeType) {
      case 1:
        return node.dataset.urlPart || node.textContent;
        break;
      case 3:
        return node.textContent;
        break;
      default:
        '';
        break;
    }
  }

  formatSourceURL() {
    const url = this.parseURL();
    const parts = url.split('**');
    const newUrl = parts.join(
      '<span class="badge badge-secondary url-part url-part-pattern" data-url-part="**" contenteditable="false"><i class="fa fa-asterisk" aria-hidden="true"></i><i class="fa fa-asterisk" aria-hidden="true"></i></span>',
    );
    return newUrl;
  }

  syncSourceUrl(e) {
    if (e.detail.content) {
      this.singleInputTarget.innerHTML = e.detail.content;
    }
  }

  moveCaretToEnd(el) {
    const selection = window.getSelection();
    const range = document.createRange();
    selection.removeAllRanges();
    range.selectNodeContents(el);
    range.collapse(false);
    selection.addRange(range);
    el.focus();
  }

  matchTypeChanged() {
    let type = this.matchTypeTarget.value;
    let url = this.normalizeURL(this.url);
    if (url) {
      switch (type) {
        case 'starts_with':
          url.pathname = this.stripPattern(url.pathname);
          url.pathname = url.pathname + '**';
          break;
        case 'ends_with':
          url.pathname = this.stripPattern(url.pathname);
          url.pathname = '/**' + url.pathname;
          break;
        default:
          url.pathname = this.stripPattern(url.pathname);
          break;
      }
      this.singleInputTarget.innerHTML = this.withoutProtocol(url.toString());
      this.sourceUpdated();
    }
  }

  withoutProtocol(url) {
    return url.replace(/^http[s]*:\/\//, '');
  }

  stripPattern(url) {
    url = url.replace(/\*\*/g, '');
    return url.replace(/\/{2,}/g, '/');
  }

  updateMatchType() {
    // programmatically update select2 field
    $(this.matchTypeTarget).val(this.matchType);
    $(this.matchTypeTarget).trigger('change');
  }

  get matchType() {
    const url = this.normalizeURL(this.url);
    if (url?.pathname.endsWith('**')) {
      return 'starts_with';
    } else if (url?.pathname.startsWith('/**')) {
      return 'ends_with';
    } else {
      return 'exact';
    }
  }

  get url() {
    return this.parseURL();
  }

  show() {
    this.singleInputTarget.disabled = false;
    if (this.hasMatchTypeTarget) {
      this.updateMatchType();
    }
    this.sourceUpdated();
  }

  hide() {
    this.singleInputTarget.disabled = true;
  }
}
