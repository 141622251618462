import { Controller } from '@hotwired/stimulus';

export default class Taggings extends Controller {
  static targets = ['disableable', 'noMatchTerm', 'selectedEntityIds', 'taggings', 'taggedEntityType', 'tagsWithTransitions'];
  static values = {
    url: String,
  };

  initialize() {
    this.selectionChanges = {};
    this.createTargetFrame = 'create-tag';
  }

  connect() {
    // In it's current version, Turbo inserts a form at the end of the body.  This means that the turbo:submit-end event will only bubble up to the body.
    // We add an event listener for this event and dispatch a new event on the target frame element if it is specified in the link's data-turbo-frame attribute.
    // An issue has been logged to discuss this https://github.com/hotwired/turbo/issues/930
    document.querySelector('body').addEventListener(
      'turbo:submit-end',
      (event) => {
        if (event.target.tagName == 'FORM' && 'turboFrame' in event.target.dataset && event.target.dataset.turboFrame == this.createTargetFrame) {
          const frame = document.getElementById(event.target.dataset.turboFrame);
          if (frame) {
            frame.dispatchEvent(new CustomEvent('turbo:submit-end', { bubbles: false, cancelable: true }));
          }
        }
      },
      true,
    );
  }

  getTaggings = () => {
    fetch(this.urlValue + this.params())
      .then((response) => response.text())
      .then(this.setList);
  };

  setList = (html) => {
    this.taggingsTarget.innerHTML = html;
    this.taggingsTarget.dispatchEvent(new CustomEvent('easyredir:list.filter', { bubbles: true }));
  };

  updateDisableds = (event) => {
    if (this.disableableTargets.length > 0) {
      this.disableableTargets.forEach((el) => {
        el.disabled = event.detail.itemsFilterable == event.detail.itemsFiltered;
      });
    }
  };

  checkboxClick = (event) => {
    this.selectionChanges[`${event.target.defaultValue}`] = event.target.checked;
    this.tagsWithTransitionsTarget.value = JSON.stringify(this.selectionChanges);
  };

  applyTag = (_event) => {
    this.getTaggings();
  };

  updateCreateLink = (event) => {
    let url = new URL(event.currentTarget.href);
    url.searchParams.set('tag[name]', this.noMatchTermTarget.innerHTML);
    event.currentTarget.href = url;
    return true;
  };

  params = () => {
    const entityIds = this.bulkEntityIds();
    if (!entityIds) return '';

    const encodedParams = new URLSearchParams({ entityIds: entityIds, entityType: this.entityType() });
    return '?' + encodedParams;
  };

  bulkEntityIds = () => {
    return this.selectedEntityIdsTarget.value;
  };

  entityType = () => {
    return this.taggedEntityTypeTarget.value;
  };
}
