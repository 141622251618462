import ApplicationController from './application_controller';
import { getJSON } from './controller_helpers';
import { render } from 'timeago.js';

export default class extends ApplicationController {
  static values = {
    url: String,
    page: Number,
    pages: Number,
  };
  static targets = ['paginationLink', 'nextPageCount'];
  static classes = ['noMoreResults'];

  loadMore(event) {
    event.preventDefault();
    getJSON(`${this.urlValue}?page=${++this.pageValue}`, (data) => {
      // get items html from response
      let new_items = this.makeItems(data.html);

      // insert before the pagination link
      this.element.insertBefore(new_items, this.paginationLinkTarget);

      // hide the pagination link if we have loaded all data
      this.togglePaginationLink(data.pages);

      // update the next page count
      this.nextPageCountTarget.textContent = this.nextPageCount(
        data.count,
        data.offset,
        data.in,
        data.items,
      );
    });
  }

  nextPageCount(count, offset, current, items) {
    return Math.min(count - (offset + current), items);
  }

  togglePaginationLink(total_pages) {
    if (this.pageValue == total_pages) {
      this.paginationLinkTarget.classList.add(this.noMoreResultsClass);
    } else {
      this.paginationLinkTarget.classList.remove(this.noMoreResultsClass);
    }
  }

  makeItems(html) {
    let template = document.createElement('template');
    template.innerHTML = html.trim();
    let nodes = template.content.querySelectorAll('time.js-relative-datetime');
    render(nodes);
    return template.content;
  }
}
