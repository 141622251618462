import ApplicationController from './application_controller';

export default class extends ApplicationController {
  normalizeURL(urlStr) {
    try {
      let url = urlStr.startsWith('http://') || urlStr.startsWith('https://') ? urlStr : `http://${urlStr}`;
      let pathHostname = url.split('//', 2)[1];
      let urlObj = new URL(url);
      urlObj.valid = pathHostname.includes('/');
      return urlObj;
    } catch {}
  }

  sourceUpdated(e) {
    this.dispatch('sourceUpdated', { detail: { content: e.currentTarget.value } });
  }
}
